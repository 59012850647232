import { render } from 'react-dom'
import React, { useState } from 'react'
import { useSprings, animated, interpolate } from 'react-spring'
import { useGesture } from 'react-with-gesture'
import './styles.css'

function whastapp(cardNames) {
  const formattedCardNames = cardNames.map((name) => name.replace(/\.jpg$/, '')).reverse();
  const message = `Hello,%20stranger.%0A${formattedCardNames.join('%20%20')}`;
  const whatsappLink = `https://api.whatsapp.com/send?phone=56994991536&text=${(message)}`;
  window.open(whatsappLink, '_self');
}

function getRandomCards() {
  const cards = [
    '/tarot-cards/☦️.jpg',
    '/tarot-cards/⚖️.jpg',
    '/tarot-cards/⛺.jpg',
    '/tarot-cards/✨.jpg',
    '/tarot-cards/🃏.jpg',
    '/tarot-cards/🌏.jpg',
    '/tarot-cards/🌛.jpg',
    '/tarot-cards/🌞.jpg',
    '/tarot-cards/🍵.jpg',
    '/tarot-cards/👺.jpg',
    '/tarot-cards/💀.jpg',
    '/tarot-cards/💞.jpg',
    '/tarot-cards/💥.jpg',
    '/tarot-cards/📯.jpg',
    '/tarot-cards/🔮.jpg',
    '/tarot-cards/🗽.jpg',
    '/tarot-cards/🙃.jpg',
    '/tarot-cards/🚀.jpg',
    '/tarot-cards/🎡.jpg',
    '/tarot-cards/🤴.jpg',
    '/tarot-cards/🦁.jpg',
    '/tarot-cards/🪄.jpg'
  ];

  const randomCards = [];
  while (randomCards.length < 3) {
    const randomIndex = Math.floor(Math.random() * cards.length);
    if (!randomCards.includes(cards[randomIndex])) {
      randomCards.push(cards[randomIndex]);
    }
  }

  return randomCards;
}

const cards = getRandomCards();

const to = (i) => ({ x: 0, y: i * -4, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 });
const from = (i) => ({ x: 0, y: i * -4, rot: 0, scale: 1.5, y: -1000 });
const trans = (r, s) => `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

function Deck() {
  const [gone] = useState(() => new Set());

  const [props, set] = useSprings(cards.length, (i) => ({ ...to(i), from: from(i) }));

  const bind = useGesture(({ args: [index], down, delta: [xDelta], direction: [xDir], velocity }) => {
    const trigger = velocity > 0.15;
    const dir = xDir < 0 ? -1 : 1;
    if (!down && trigger) gone.add(index);
    set((i) => {
      if (index !== i) return;
      const isGone = gone.has(index);
      const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0;
      const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0);
      const scale = down ? 1.1 : 1;
      return { x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 1200 : isGone ? 200 : 500 } };
    });
    if (!down && gone.size === cards.length) setTimeout(() => whastapp(cards.map((cardUrl) => cardUrl.substring(cardUrl.lastIndexOf('/') + 1))), 100);
  });

  return props.map(({ x, y, rot, scale }, i) => (
    <animated.div key={i} style={{ transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`) }}>
      <animated.div
        {...bind(i)}
        style={{ transform: interpolate([rot, scale], trans), backgroundImage: `url(${cards[i]})` }}
      />
    </animated.div>
  ));
}

render(<Deck />, document.getElementById('root'));